/**
 * make the style work cross browsers
*/
@mixin crossBrowser($property, $value) {
  $prefixes: webkit, moz, ms, o;

  #{$property}: #{$value};

  @each $prefix in $prefixes {
    -#{$prefix}-#{$property}: $value;
  }
}

@mixin transform($value) {
  @include crossBrowser(transform, $value);
  @include crossBrowser(will-change, transform);
}

@mixin transition($value) {
  @include crossBrowser(transition, $value);
}

@mixin boxShadow($value) {
  @include crossBrowser(box-shadow, $value);
}

// hide the overflow
@mixin ellipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// create circle
@mixin circle($width, $height, $backgound) {
  width: $width;
  height: $height;
  border-radius: 50%;
  background: $backgound;
}

// fade
@mixin fade($type) {
  @include crossBrowser(transition, visibility 1s, opacity 1s);
  @if $type== "hide" {
    visibility: hidden;
    opacity: 0;
  } @else if $type== "show" {
    visibility: visible;
    opacity: 1;
  }
}

// animation
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @-o-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin absolute(
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $z-index: initial
) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  z-index: $z-index;
}

@mixin placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

// responsive design mixins
$breakpoints: (
  "xs": 300px,
  "sm": 576px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
  "xxl": 1400px,
);

@mixin respond-above($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (min-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

@mixin xs() {
  @media screen and (max-width: 575px) {
    @content;
  }
}

@mixin sm() {
  @media screen and (min-width: 576px) and (max-width: 767px) {
    @content;
  }
}

@mixin md() {
  @media screen and (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}

@mixin lg() {
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}

@mixin xl() {
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    @content;
  }
}

@mixin xxl() {
  @media screen and (min-width: 1400px) {
    @content;
  }
}

@mixin max-w-xs() {
  @media screen and (max-width: 575px) {
    @content;
  }
}

@mixin max-w-sm() {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin max-w-md() {
  @media screen and (max-width: 991px) {
    @content;
  }
}

@mixin max-w-lg() {
  @media screen and (max-width: 1199px) {
    @content;
  }
}

@mixin max-w-xl() {
  @media screen and (max-width: 1399px) {
    @content;
  }
}

@mixin min-w-sm() {
  @media screen and (min-width: 576px) {
    @content;
  }
}

@mixin min-w-md() {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin min-w-lg() {
  @media screen and (min-width: 992px) {
    @content;
  }
}

@mixin min-w-xl() {
  @media screen and (min-width: 1200px) {
    @content;
  }
}

@mixin min-w($width) {
  @media screen and (min-width: $width) {
    @content;
  }
}

@mixin animation($animation) {
  animation: $animation;
  -webkit-animation: $animation;
  -moz-animation: $animation;
}
