@import "src/lib/sea/color.scss";
@import "src/lib/sea/mixin.scss";

.page {
  width: 210mm;
  // height: 297mm;
  overflow-x: auto;
  background-color: $light-700;
  margin: 0 auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;

  @media print {
    zoom: 100%;

    @page {
      size: A4;
    }
  }

  @include min-w("200px") {
    zoom: 45%;
  }

  @include min-w("400px") {
    zoom: 55%;
  }

  @include min-w("500px") {
    zoom: 65%;
  }

  @include min-w("600px") {
    zoom: 75%;
  }

  @include min-w("700px") {
    zoom: 85%;
  }

  @include min-w("800px") {
    zoom: 100%;
  }
}

.watermark {
  position: absolute;
  bottom: 2em;
  left: 50%;
  transform: translateX(-50%);
}
