@import url("https://fonts.googleapis.com/css2?family=Markazi+Text:wght@400;500;600;700&family=Rubik:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Alexandria:wght@500;600;700;800;900&family=Markazi+Text:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Markazi+Text:wght@400;500;600;700&family=Montserrat:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Markazi+Text:wght@400;500;600;700&family=Rubik:wght@500;600;700;800&display=swap");
@import "src/lib/sea/mixin.scss";
@import "src/lib/sea/color.scss";

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
}

:root {
  background-color: #ebecf0;
  // @include max-w-sm() {
  //   font-size: calc(1vw + 6px);
  // }

  // @include min-w-md() {
  //   font-size: 13px;
  // }
  font-size: 13px;
}

.ar .text {
  font-family: "Markazi Text", serif;
  font-family: "Rubik", sans-serif;
}

.ar .heading {
  font-family: "Alexandria", sans-serif;
  font-family: "Markazi Text", serif;
}

.en .text- {
  font-family: "Markazi Text", serif;
  font-family: "Montserrat", sans-serif;
}

.en .heading {
  font-family: "Markazi Text", serif;
  font-family: "Rubik", sans-serif;
}

.print-page-rtl {
  direction: rtl;
}

.print-page-ltr {
  direction: ltr;
}
