@import "src/lib/sea/color.scss";
@import "src/lib/sea/mixin.scss";

.error_mssage {
  background-color: $light-700;
  width: 90%;
  max-width: 40em;
  min-width: 25em;
  height: 25em;
  @include boxShadow(rgba(100, 100, 111, 0.2) 0px 7px 29px 0px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.status {
  font-size: 12rem;
  font-weight: 900;
  line-height: 10rem;
}

.colorNumber {
  color: $danger;
}

.message {
  font-size: 1.3rem;
  text-align: center;
}
