.avatar {
  position: relative;
  overflow: hidden;
}

.circle {
  border-radius: 50%;
  width: 11em;
  height: 11em;
}

.sharp{
  min-width: 6em;
  min-height: 8em;
}

.avatar_image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  object-fit: fill;
}
