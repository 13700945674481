// light color
$light-700: rgb(255 255 255);
$light-600: rgb(248 250 252);
$light-500: rgb(241 245 249);
$light-400: rgb(226 232 240);
$light-300: rgb(203 213 225);
$light-200: rgb(148 163 184);
$light-100: rgb(100 116 139);

$dark-300: #020a10;
$dark-200: #030f17;
$dark-100: #0a1a27;
$red: #bf0100;
$white: #ffffff;
$gray: #b5b9bc;
$green: #38a169;
$yellow: #d29f08;

$primary: #2890ff;
$secondary: #6c757d;
$success: #28a745;
$danger: #dc3545;
$warning: #ffc107;
