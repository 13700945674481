@import "src/lib/sea/color.scss";
@import "src/lib/sea/mixin.scss";

.layout {
  position: relative;
  padding: 2em 0.5em;
  min-height: 100vh;
}

.button {
  position: fixed;
  border: none;
  outline: none;
  right: 2.5em;
  bottom: 2.5em;
  cursor: pointer;
  color: $light-700;
  font-size: 1.2rem;
  font-weight: bold;
  @include circle(4.7em, 4.7em, $primary);

  @include boxShadow((0px 17px 10px -10px rgba(0,0,0,0.4)));
  @include transition(opacity 0.2s);

  &:hover {
    opacity: 0.8;
  }
}

