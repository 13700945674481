.loader-container {
  /* width: 100vw;
  height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.loader-image{
    position: absolute;
}

.loader {
  width: 75px;
  aspect-ratio: 1;
  position: relative;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  margin: -8px 0 0 -8px;
  width: 16px;
  aspect-ratio: 1;
  background: #898892;
  animation: l2-1 2s infinite, l2-2 1s infinite;
}
.loader:after {
  background: #A62639;
  animation-delay: -1s, 0s;
}
@keyframes l2-1 {
  0% {
    top: 0;
    left: 0;
  }
  25% {
    top: 100%;
    left: 0;
  }
  50% {
    top: 100%;
    left: 100%;
  }
  75% {
    top: 0;
    left: 100%;
  }
  100% {
    top: 0;
    left: 0;
  }
}
@keyframes l2-2 {
  40%,
  50% {
    transform: rotate(0.25turn) scale(0.5);
  }
  100% {
    transform: rotate(0.5turn) scale(1);
  }
}
